import React from "react"
import Layout from "../components/layouts/AboutLayout"
import Seo from "gatsby-theme-blog-starter/src/components/seo/Seo"
import PageLayout from "gatsby-theme-blog-starter/src/components/layout/PageLayout";
import {Grid} from '@material-ui/core';

//import MD from './quimd.md'

export default ({ data }) => (
  <>
    <Layout pageTitle="Qui est perceptible">
      <Seo
        title="Qui est perceptible"
        description="Qui est perceptible"
        tags={["info"]}
        slug="/qui"
      />
      <PageLayout title="Qui est perceptible.fr ?">
        <br/>
        <Grid container spacing={1}>
          <Grid item xs={3} spacing={3}>
              <img src="/Alain.jpg" alt="Alain" width="100%" heigth="200"/>
          </Grid>
          <Grid item xs={6} spacing={3}>
            <b>perceptible.fr</b> a été crée par Alain Roan, Ingénieur Supelec. Il a participé à l'élaboration et la diffusion des méthodes et des technologies visant à l'amélioration des pratiques et des façons de faire de l'ingénierie des systèmes/ logiciels complexes et critiques des produits industriels en Europe et aux États-Unis. Il a occupé la plupart des responsabilités de l'entreprise: R&D, développement/ production, services/ conseil, marketing, ventes, support, direction générale.
          </Grid>
        </Grid>
        <Grid item spacing={1}>
          <b>perceptible.fr</b> est à la convergence de différentes forces :
          <ul>
            <li>la reconnaissance que le maillon faible de la pyramide désormais très haute d'outils et de méthodes, reste (comme pour toujours) <b>la décision humaine</b>
            </li>
            <li>le constat que partout y compris dans les plus grandes entreprises équipées des logiciels de gestion les plus sophistiqués, la plupart des salariés utilisent des tableurs pour <b>mémoriser, calculer et présenter des données qui leur servent à mesurer, comprendre, expliquer et décider</b>
            </li>
            <li>l'avènement de technologies de rupture qui bouleversent et court-circuitent les vieilles façons de penser et de faire. <b>Neurosciences, technologies web, cloud , open data... </b>et la robustesse des méthodes agiles qui à petits pas font de grandes enjambées
            </li>
          </ul>
          <b>perceptible.fr</b> exploite les meilleures technologies disponibles pour rendre le contrôle à la décision humaine: des données visuelles qui "parlent" à leurs destinataires.
        </Grid>     

        <h2>Me joindre: </h2>
          <ul>
            <li><a href="https://twitter.com/AlainRoan"><b>Twitter</b></a></li>
            <li><a href="https://www.linkedin.com/in/AlainRoan"><b>LinkedIn</b></a></li>
            <li><a href="mailto:alain.roan@perceptible.fr"><b>Mail</b></a></li>
            <li><a href="tel:0619655383"><b>Téléphone: 0619655383</b></a></li>
          </ul>
      </PageLayout>
    </Layout>
  </>
)
